import { Listbox } from "@headlessui/react";
import { FunctionComponent, HTMLAttributes, useState } from "react";

import ChevronArrowDown from "@/components/Icons/ChevronArrowDown";

const options = [
  { value: "all", label: "All" },
  { value: "images", label: "Images" },
];

interface Prop extends HTMLAttributes<HTMLDivElement> {}

const ArtworkMediaTypeDropdown: FunctionComponent<Prop> = ({ ...rest }) => {
  const [selectedOption, setSelectedOption] = useState(options[0]);

  return (
    <div {...rest}>
      <Listbox value={selectedOption} onChange={setSelectedOption}>
        <Listbox.Button className="inline-flex items-center justify-center pl-[32px] text-right focus:outline-none">
          <div className="inline-flex h-16 items-center justify-center gap-4 ">
            <div className="w-14 text-left">{selectedOption.label}</div>
            <ChevronArrowDown className="fill-tenant-gray-dark stroke-[2px]" />
          </div>
          <div className="ml-6 h-[30px] border-r border-tenant-gray-medium" />
        </Listbox.Button>
        <Listbox.Options className="absolute mt-[1px] w-[144px] bg-white py-1 ring-1  ring-black focus:outline-none sm:text-sm">
          {options.map(option => (
            <Listbox.Option className=" " key={option.label} value={option}>
              {({ active }) => (
                <div
                  className={`flex w-full items-center justify-center py-5 ${
                    active ? "bg-tenant-blue-light text-black" : " text-black"
                  }`}
                  role="button"
                >
                  {option.label}
                </div>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
    </div>
  );
};

export default ArtworkMediaTypeDropdown;

import { clsMerge } from "@artifactlabs/shared-react-utils";
import React, { useEffect, useMemo, useState } from "react";
import { useResetRecoilState } from "recoil";

import ArtworkSearchAutoComplete from "@/components/ArtworkSearchAutocomplete";
import Container from "@/components/Container";
import GalleryCategoryTab, { TabItem } from "@/components/GalleryCategoryTab";
import { MainLayout } from "@/components/Layout/MainLayout";
import MasonryGallery from "@/components/LoginButton/MasonryGallery";
import { useSearch } from "@/hooks/artworks/useSearch";
import { useCustomTranslation } from "@/hooks/useCustomTranslation";
import { NextPageWithLayout } from "@/pages/page";
import autocompleteAtom from "@/recoil/autocomplete";

const PAGE_SIZE = parseInt(process.env.NEXT_PUBLIC_HOME_PAGE_SEARCH_SIZE_DESKTOP!);

const Home: NextPageWithLayout = () => {
  //
  const [isSearchResultBoxIsOpen, setIsSearchResultBoxIsOpen] = useState(false);
  const { t } = useCustomTranslation("home");
  const [currentNavIndex, setCurrentNavIndex] = useState<number>(0);

  const navItems = useMemo(
    () =>
      ["featured", "images"].map((subject, idx) => ({
        subject,
        current: currentNavIndex == idx,
        handleClick: () => setCurrentNavIndex(idx),
      })),
    [currentNavIndex],
  );

  const resetAutocompleteAtom = useResetRecoilState(autocompleteAtom);

  const { data, loading, error, refetch } = useSearch({
    keyword: "",
    options: {
      limit: PAGE_SIZE,
      sort: 4,
    },
  });

  useEffect(() => {
    refetch();
  }, [currentNavIndex, refetch]);

  useEffect(() => {
    resetAutocompleteAtom();
  }, [resetAutocompleteAtom]);

  return (
    <div className="flex grow flex-col bg-white">
      {/* Page Title */}
      <Container
        className={clsMerge(
          " flex h-[80px] flex-col content-baseline items-center justify-center bg-tenant-gray-soft md:h-[188px] md:justify-end",
          "custom-theme-container-background",
        )}
      >
        <div className="flex w-full flex-col items-center justify-center">
          <h1 className="custom-theme-home-header-title text-[20px] font-[400px] leading-[24px] tracking-wider md:text-[48px] md:leading-[60px]">
            {t("TITLE")}
          </h1>
        </div>
      </Container>

      {/* Search bar */}
      <Container className={clsMerge(" bg-tenant-gray-soft")}>
        <div className="custom-theme-container-background flex w-full flex-col items-center justify-center md:h-[102px] md:px-10">
          <ArtworkSearchAutoComplete
            className="w-full"
            currentState={(isOpen: boolean) => {
              setIsSearchResultBoxIsOpen(isOpen);
            }}
          />
        </div>
      </Container>

      <GalleryCategoryTab>
        {navItems.map(({ subject, current, handleClick }) => (
          <TabItem current={current} handleClick={handleClick} key={subject}>
            {subject}
          </TabItem>
        ))}
      </GalleryCategoryTab>

      {error && (
        <div className="mx-auto flex w-full items-center justify-center bg-white pt-10">
          {t("common.errors.SERVICE_UNAVAILABLE")}
        </div>
      )}

      <Container className="relative px-10">
        <MasonryGallery
          assets={data?.searchArtworks}
          className="min-h-screen bg-white"
          loading={loading}
        />
        {isSearchResultBoxIsOpen && (
          <div className="absolute left-0 top-0 z-[5] h-full w-full bg-black bg-opacity-75 md:hidden" />
        )}
      </Container>
    </div>
  );
};

Home.getLayout = (page: React.ReactNode) => {
  const {
    props: { reskinningConfig, headers },
  } = page as { props: any };

  console.log("DEBUG: home page headers", headers);

  return (
    <MainLayout reskinningConfig={reskinningConfig} withLogo={true} showSubscribeNewsletter>
      {page}
    </MainLayout>
  );
};

export default Home;

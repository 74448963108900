import Image from "next/image";
import { FunctionComponent, HTMLAttributes, memo } from "react";

import AppLink from "@/components/AppLink";

interface Props extends HTMLAttributes<HTMLDivElement> {
  navigateTo: string;
  imageSrc: string;
}

export const GridImageItem: FunctionComponent<Props> = ({ imageSrc, navigateTo }) => {
  return (
    <div className="w-full">
      <AppLink href={navigateTo}>
        <Image
          alt=""
          className="h-auto w-full rounded-lg"
          height={300}
          quality={1}
          sizes="100vw"
          src={imageSrc}
          width={500}
          unoptimized
        />
      </AppLink>
    </div>
  );
};

const areEqual = (oldProps: Props, newProps: Props) => {
  return oldProps.navigateTo == newProps.navigateTo;
};

export default memo(GridImageItem, areEqual);
